class Material {

    constructor() {
        this.initMobileMenu();
    }

    initMobileMenu() {
        if (this.triggerMobileMenuExists() && this.mobileMenuExists()) {
            const mobileMenuTrigger = document.getElementById('open-burger-menu');
            const mobileMenu = document.getElementById('burger-navigation');
            mobileMenuTrigger.addEventListener('click', () => { mobileMenu.open = !mobileMenu.open; });
        }
    }

    triggerMobileMenuExists() {
        return document.getElementById('open-burger-menu') != null;
    }

    mobileMenuExists() {
        return document.getElementById('burger-navigation') != null;
    }
}

export function initMaterial() {
    return new Material();
}
